<template>
  <div class="habibifier">
    <canvas
        :width="canvasWidth"
        :height="canvasHeight"
        @mousedown="handleMouseDown"
        @mousemove="handleMouseMove"
        @mouseup="handleMouseUp"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd"
    ></canvas>
    <div class="options">
      <div class="sticker-selector">
        <img src="/stickers/1.png" @click="selectSticker('/stickers/1.png')" alt="Hat 1">
        <img src="/stickers/15.png" @click="selectSticker('/stickers/15.png')" alt="Hat 15">
        <img src="/stickers/2.png" @click="selectSticker('/stickers/2.png')" alt="Hat 2">
        <img src="/stickers/3.png" @click="selectSticker('/stickers/3.png')" alt="Hat 3">
        <img src="/stickers/35.png" @click="selectSticker('/stickers/35.png')" alt="Hat 35">
        <img src="/stickers/4.png" @click="selectSticker('/stickers/4.png')" alt="Hat 4">
      </div>
      <div class="controls">
        <div class="rotation">
          <label>Rotation: {{ rotation }}°</label>
          <input type="range" v-model="rotation" min="0" max="360" />
        </div>
        <div class="scale">
          <label>Scale: {{ scale }}%</label>
          <input type="range" v-model="scale" min="-50" max="100" />
        </div>
      </div>
      <div class="all-btns">
        <div class="all-btns_buttons">
          <input type="file" @change="handleImageUpload" accept="image/*" style="display: none" ref="fileInput">
          <button class="all-btns_buttons_select" @click="openFileInput">Select image</button>
          <button class="all-btns_buttons_download" @click="downloadImage">Download</button>
        </div>
        <button class="all-btns_tweet-btn" @click="tweetHabibified">Share on X</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';

const processedImage = ref<string | null>(null);
const canvasWidth = ref(500);
const canvasHeight = ref(500);
const selectedSticker = ref<string | null>(null);
const stickerPosition = ref({ top: 50, left: 50 });
const isMovingSticker = ref(false);
const prevMousePos = ref({ x: 0, y: 0 });
const rotation = ref(0);
const scale = ref(0);
const fileInput = ref<HTMLInputElement | null>(null);

const canvasRef = ref<HTMLCanvasElement | null>(null);

onMounted(() => {
  canvasRef.value = document.querySelector('canvas');
  watchCanvasSize();
});

watch([selectedSticker, rotation, scale], () => {
  drawImageWithSticker()
});


function openFileInput() {
  if (fileInput.value) {
    fileInput.value.click();
  }
}

function handleImageUpload(event: InputEvent) {
  const file = (event.target as HTMLInputElement)?.files?.[0];

  if (file) {
    const reader = new FileReader();

    reader.onload = () => {
      processedImage.value = reader.result as string;
      drawImageWithSticker();
    };

    reader.readAsDataURL(file);
  }
}

function downloadImage() {
  const canvas = canvasRef.value;

  if (!canvas) {
    console.error("Canvas not available.");
    return;
  }

  const dataURL = canvas.toDataURL('image/png');
  const link = document.createElement('a');
  link.href = dataURL;
  link.download = 'habibified_image.png';
  link.click();
}

function drawImageWithSticker() {
  const canvas = canvasRef.value;

  if (!canvas) {
    console.error("Canvas not available.");
    return;
  }

  const context = canvas.getContext('2d');

  if (!context) {
    console.error("Canvas context not available.");
    return;
  }

  // Очистка канваса
  context.clearRect(0, 0, canvas.width, canvas.height);

  // Отрисовка основного изображения
  const img = new Image();
  img.src = processedImage.value as string;

  img.onload = () => {
    // Определяем коэффициенты масштабирования для помещения изображения в канвас
    const scaleX = canvas.width / img.width;
    const scaleY = canvas.height / img.height;
    const scaleToFit = Math.min(scaleX, scaleY);

    // Рассчитываем новые размеры изображения
    const newWidth = img.width * scaleToFit;
    const newHeight = img.height * scaleToFit;

    // Рассчитываем положение изображения в центре канваса
    const x = (canvas.width - newWidth) / 2;
    const y = (canvas.height - newHeight) / 2;

    context.drawImage(img, x, y, newWidth, newHeight);

    // Сохранение текущего состояния контекста
    context.save();

    const stickerContext = canvas.getContext('2d');
    if (!stickerContext) {
      return
    }

    // Отрисовка стикера
    if (selectedSticker.value) {
      const sticker = new Image();
      sticker.src = selectedSticker.value;

      sticker.onload = () => {
        stickerContext.save();

        // Указываем центр стикера как точку вращения
        stickerContext.translate(
            stickerPosition.value.left + 350 * (1 + scale.value / 100) / 2,
            stickerPosition.value.top + 350 * (1 + scale.value / 100) / 2
        );

        stickerContext.rotate(((rotation.value) / 360) * Math.PI * 2);

        // Возвращаемся к исходным координатам
        stickerContext.translate(
            -(stickerPosition.value.left + 350 * (1 + scale.value / 100) / 2),
            -(stickerPosition.value.top + 350 * (1 + scale.value / 100) / 2)
        );

        // Используем естественные размеры стикера
        stickerContext.drawImage(sticker, stickerPosition.value.left, stickerPosition.value.top, 350 * (1 + scale.value / 100), 350 * (1 + scale.value / 100));

        stickerContext.restore();
      };
    }
  };
}

function watchCanvasSize() {
  watch([canvasWidth, canvasHeight, rotation, scale], () => {
    drawImageWithSticker();
  });
}

function selectSticker(sticker: string) {
  selectedSticker.value = sticker;
}

function handleMouseDown(event: MouseEvent) {
  const mouseX = event.clientX - canvasRef.value?.offsetLeft;
  const mouseY = event.clientY - canvasRef.value?.offsetTop;

  const isMouseOverSticker =
      mouseX >= stickerPosition.value.left &&
      mouseX <= stickerPosition.value.left + event.target.clientWidth &&
      mouseY >= stickerPosition.value.top &&
      mouseY <= stickerPosition.value.top + event.target.clientHeight;

  if (isMouseOverSticker) {
    isMovingSticker.value = true;
    prevMousePos.value = { x: mouseX, y: mouseY };
  }
}

let isStickerPositionChanged = false;

function handleMouseMove(event: MouseEvent) {
  if (isMovingSticker.value) {
    const mouseX = event.clientX - canvasRef.value?.offsetLeft;
    const mouseY = event.clientY - canvasRef.value?.offsetTop;

    const offsetX = mouseX - prevMousePos.value.x;
    const offsetY = mouseY - prevMousePos.value.y;

    stickerPosition.value = {
      top: stickerPosition.value.top + offsetY,
      left: stickerPosition.value.left + offsetX,
    };

    prevMousePos.value = { x: mouseX, y: mouseY };

    isStickerPositionChanged = true;
    // drawImageWithSticker();
  }
}

function handleMouseUp() {
  isMovingSticker.value = false;
  if (isStickerPositionChanged) {
    drawImageWithSticker();
    isStickerPositionChanged = false;
  }
}
function handleTouchStart(event: TouchEvent) {
  const touch = event.touches[0];
  const mouseX = touch.clientX - canvasRef.value?.offsetLeft;
  const mouseY = touch.clientY - canvasRef.value?.offsetTop;

  const isTouchOverSticker =
      mouseX >= stickerPosition.value.left &&
      mouseX <= stickerPosition.value.left + event.target.clientWidth &&
      mouseY >= stickerPosition.value.top &&
      mouseY <= stickerPosition.value.top + event.target.clientHeight;

  if (isTouchOverSticker) {
    isMovingSticker.value = true;
    prevMousePos.value = { x: mouseX, y: mouseY };
  }
}

function handleTouchMove(event: TouchEvent) {
  if (isMovingSticker.value) {
    const touch = event.touches[0];
    const mouseX = touch.clientX - canvasRef.value?.offsetLeft;
    const mouseY = touch.clientY - canvasRef.value?.offsetTop;

    const offsetX = mouseX - prevMousePos.value.x;
    const offsetY = mouseY - prevMousePos.value.y;

    stickerPosition.value = {
      top: stickerPosition.value.top + offsetY,
      left: stickerPosition.value.left + offsetX,
    };

    prevMousePos.value = { x: mouseX, y: mouseY };

    isStickerPositionChanged = true;
  }
}

function handleTouchEnd() {
  if (isMovingSticker.value) {
    isMovingSticker.value = false;
    if (isStickerPositionChanged) {
      drawImageWithSticker();
      isStickerPositionChanged = false;
    }
  }
}

function tweetHabibified() {
  const tweetText = "I just joined $ARAB FAMILY and got #HABIBIFIED \n" +
      "\n" +
      "@arabcatcoin\n" +
      "CA: BFpchrNVhyTRzMNAg9QkiZfRN2vqRBwcYoTX8qgkbDvm \n" +
      "Chart: https://dexscreener.com/solana/EfQGhHPSc9mU5gooddL3cjxsGPcEJggZuQc9L87vif6t\n" +
      "Tg: https://t.me/arabcatcoin\n" +
      "\n" +
      "\n" +
      "Get #HABIBIFIED here: habibify.xyz";
  const twitterIntentURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
  window.open(twitterIntentURL, '_blank');
}

// Замени события мыши на события касания
onMounted(() => {
  canvasRef.value?.addEventListener('touchstart', handleTouchStart);
  canvasRef.value?.addEventListener('touchmove', handleTouchMove);
  canvasRef.value?.addEventListener('touchend', handleTouchEnd);
})

</script>

<style lang="sass" scoped>
.habibifier
  margin-top: 5%
  display: flex
  align-items: center
  align-self: center
  gap: 40px
  margin-bottom: 60px
  flex: 1
.options
  display: flex
  flex-direction: column
  gap: 20px

.sticker-selector
  display: flex
  padding: 20px
  margin: auto
  border: 2px solid #000000
  background-color: white
  border-radius: 10px
  width: 600px
  gap: 10px
  justify-content: space-between
  flex-wrap: wrap
  button
    margin-right: 10px

  img
    width: 150px
    transition: transform 0.2s ease-in-out

  img:hover
    transform: scale(1.1)
    cursor: pointer


.controls
  display: flex
  gap: 50px
  align-items: center
  justify-content: center
  .rotation
    display: flex
    flex-direction: column

  .scale
    display: flex
    flex-direction: column

.all-btns
  display: flex
  &_buttons
    display: flex
    justify-content: center
    gap: 15px
    &_select
      width: 200px
      height: 50px
      background-color: white
      font-family: 'Roboto Slab', serif
      font-weight: bold
      font-size: 30px
      border-radius: 10px
      border: 2px solid black
      transition: transform 0.2s ease-in-out
      &:hover
        transform: scale(1.1)
        cursor: pointer
    &_download
      width: 200px
      height: 50px
      background-color: black
      font-family: 'Roboto Slab', serif
      font-weight: bold
      font-size: 30px
      color: white
      border-radius: 10px
      border: 2px solid black
      transition: transform 0.2s ease-in-out
      &:hover
        transform: scale(1.1)
        cursor: pointer
  &_tweet-btn
    margin-left: 15px
    width: 200px
    height: 50px
    background-color: #1DA1F2
    font-family: 'Roboto Slab', serif
    font-weight: bold
    font-size: 30px
    color: white
    border-radius: 10px
    border: 2px solid #1DA1F2
    transition: transform 0.2s ease-in-out
    &:hover
      transform: scale(1.1)
      cursor: pointer


canvas
  display: flex
  margin: auto
  border: 2px solid #000000
  border-radius: 10px
  cursor: pointer
  background-color: #ffffff
  touch-action: none

@media (max-width: 480px)
  .habibifier
    gap: 10px
    flex-direction: column
  canvas
    margin: 40px
    width: 300px
  .sticker-selector
    width: 300px
    display: flex
    flex-wrap: nowrap
    overflow: scroll
    & img
      width: 70px
  .all-btns
    flex-direction: column
    &_buttons
      gap: 10px
      align-items: center
      &_select
        font-size: 20px
        width: 150px
      &_download
        font-size: 20px
        width: 150px
    &_tweet-btn
      margin-top: 10px
      margin-left: 0
      align-self: center
      font-size: 20px
      width: 150px


</style>
