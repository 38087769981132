<template>
  <div class="separator">
    <p class="text" v-for="i in 50" :key="i">
      /9_LIVES_4_WIVES
    </p>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="sass">
.separator
  position: sticky
  bottom: 0
  border-top: 3px solid black
  border-bottom: 3px solid black
  max-height: 60px
  background-color: #12703c
  color: white
  display: flex
  align-items: center
  overflow: hidden
  max-width: 100%
.text
  animation: news-ticker 40s linear alternate infinite
  font-size: 22pt
  margin-right: 80px
@keyframes news-ticker
  0%
    transform: translateX(10%)
  100%
    transform: translateX(-1000%)
@media (max-width: 480px)
  .separator
    height: 40px
  .text
    font-size: 15pt
</style>